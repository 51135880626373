












































































































































































































































import T3Link from '~/components/General/T3Link.vue';
import { SfFooter, SfList, SfImage, SfMenuItem } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import { useMainNavStore } from '../stores/mainNavStore';
import { computed } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {
    SfFooter,
    SfList,
    SfImage,
    SfMenuItem,
    T3Link,
  },
  setup() {
    const mainNavStore = useMainNavStore();

    let data = computed(() => mainNavStore.footerArrangedData);
    let lowerContent = computed(() => mainNavStore.footerLowerContent);
    let lowerContentCenter = computed(
      () => mainNavStore.footerLowerContentCenter,
    );
    let lowerContentRight = computed(
      () => mainNavStore.footerLowerContentRight,
    );
    let footerMeta = computed(() => mainNavStore.footerMeta);
    let copyright = computed(() => mainNavStore.copyright);

    return {
      addBasePath,
      data,
      lowerContent,
      lowerContentCenter,
      lowerContentRight,
      footerMeta,
      copyright,
    };
  },
  data() {
    return {
      isMobile: false,
      desktopMin: 1024,

      isActive: null,
    };
  },
  methods: {
    getChildren(list) {
      if (list?.length) {
        return list;
      } else {
        return [];
      }
    },

    changeActive(index) {
      this.isActive = this.isActive === index ? null : index;
    },
  },

  mounted() {},
});
